/**
 * Color scheme
 * ----------------------------------------------------------------------------
 */
$color-gray: #F1F1F1;
$color-gray-light: #F8F8F9;
$color-gray-light-x: #D8D8D8;
$color-gray-light-xx: #AFAFAF;
$color-gray-medium: #888888;
$color-gray-dark: #5A5A5A;

$color-black: #000000;
$color-white: #ffffff;

$color-purple: #5843BE;
$color-purple-light: #F6F4FF;
$color-turquoise: #3ECDB7;
$color-green: #176F3E;
$color-green-light: #C1EED5;
$color-red: #D03343;
$color-red-medium: #F1CEC6;
$color-red-dark: #600404;
$color-red-light: #FFEBED; /* @Lex, maybe find better color? */
$color-blue: #3C7C96;
$color-blue-light: #ECF3F6;
$color-blue-medium: #ADCBD8;
$color-orange: #FAA21B;
$color-orange-dark: #733312;
$color-orange-medium: #F6E1C0;
$color-orange-light: #FEF8EA;

$color-primary: $color-blue;
$color-primary-light: $color-blue-light;
$color-primary-medium: $color-blue-medium;
$color-secondary: #767676;
$color-success: $color-green;
$color-warning: #ffae00;
$color-alert: $color-red;
