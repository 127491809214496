/**
 * Custom fonts
 * ----------------------------------------------------------------------------
 */
$font-primary: 'Sofia Pro', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;

// Regular

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/sofia/SofiaPro.woff2') format('woff2'),
    url('../fonts/sofia/SofiaPro.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

// Italic

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/sofia/SofiaPro-Italic.woff2') format('woff2'),
    url('../fonts/sofia/SofiaPro-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

// Medium

@font-face {
	font-family: 'Sofia Pro';
	src: url('../fonts/sofia/SofiaPro-Medium.woff2') format('woff2'),
	url('../fonts/sofia/SofiaPro-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
    font-display: swap;
}

// SemiBold

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/sofia/SofiaPro-SemiBold.woff2') format('woff2'),
    url('../fonts/sofia/SofiaPro-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

// SemiBold Italic

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/sofia/SofiaPro-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/sofia/SofiaPro-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}


// Bold

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/sofia/SofiaPro-Bold.woff2') format('woff2'),
    url('../fonts/sofia/SofiaPro-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
