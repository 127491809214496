/**
 * Settings
 * ----------------------------------------------------------------------------
 */

@import 'util/util';

/* Global */
$global-width: rem-calc(1200);

/* Breakpoints */
$breakpoints: (
		small: 0,
		medium: 640px,
		large: 1024px,
		xlarge: 1200px,
		xxlarge: 1440px,
		xxxlarge: 1600px
);
$print-breakpoint: large;
$breakpoint-classes: (small medium large xlarge xxlarge xxxlarge);

/* Typography */
$global-font-size: 100%;
$body-font-family: $font-primary;
$heading-font-family: $font-primary;

/* XY Grid */
$xy-grid: true;
$grid-container: $global-width;
$grid-columns: 12;
$grid-margin-gutters: rem-calc(30);
$grid-padding-gutters: $grid-margin-gutters;
$grid-container-padding: $grid-padding-gutters;
$grid-container-max: $global-width;
$xy-block-grid-max: 8;
