/**
 * Global styles and elements used throughout the site.
 * ----------------------------------------------------------------------------
 */

.wpgdprc {
    ::-moz-selection {
        background: $color-primary;
        color: $color-white;
    }

    ::selection {
        background: $color-primary;
        color: $color-white;
    }

    a {
        transition: all $transition;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    form {
        margin-bottom: 0;
    }

    .no-bullet {
        list-style: none;
    }

    .video-container {
        position: relative;
        padding: {
            top: 25px;
            bottom: 56.25%;
        }
        height: 0;
        opacity: 0;
        transition: opacity $transition;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &.active {
            opacity: 1;
        }
    }

    img {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
        height: auto;
        -ms-interpolation-mode: bicubic;
        box-sizing: inherit;
    }
}
