/**
 * Consent modal.
 * ----------------------------------------------------------------------------
 */
.wpgdprc-consent-modal {
    display: none;
}

.wpgdprc-consent-modal.is-open {
    display: block !important;
}

.wpgdprc-consent-modal[aria-hidden="false"] .wpgdprc-consent-modal__overlay {
    animation: wpgdprcFadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.wpgdprc-consent-modal[aria-hidden="true"] .wpgdprc-consent-modal__overlay {
    animation: wpgdprcFadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.wpgdprc-consent-modal__overlay {
    will-change: transform;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000000;
    background: rgba(0, 0, 0, 0.6);
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 999999;
}

.wpgdprc-consent-modal__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    width: 90vw;
    height: 80%;
    height: 80vh;
    max-width: 800px;
    overflow-y: auto;
    background: $color-white;
    box-shadow: 0 10px 15px 0 rgba(0,0,0,0.20);
    border-radius: $radius-l;
}

.wpgdprc-consent-modal__header {
    padding: rem-calc(15);
    border-bottom: 1px solid #EDEDED;
}

:root .wpgdprc-consent-modal__title {
    font-family: $heading-font-family;
    font-size: rem-calc(20);
    font-weight: bold;
    color: $color-black;
    margin-bottom: 0;
    letter-spacing: 0;
}

:root .wpgdprc-consent-modal__title--description {
    font-size: rem-calc(24);
    margin-bottom: rem-calc(10);
}

:root .wpgdprc-consent-modal button.wpgdprc-consent-modal__close {
    position: absolute;
    top: rem-calc(12);
    right: rem-calc(10);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    color: $color-gray-dark;
    background-color: transparent;
    border: none;
    border-radius: $radius;
    transition: all $transition;

    svg {
        width: rem-calc(14);
        pointer-events: none;
    }

    svg path {
        fill: currentColor;
    }

    @include hocus {
        background-color: var(--wp-gdpr--button--background-color--darken);
        color: var(--wp-gdpr--button--color);
        box-shadow: $shadow;
    }
}

.wpgdprc-consent-modal__body {
    flex-grow: 1;
    border-bottom: 1px solid #EDEDED;
}

.wpgdprc-consent-modal__description {
	color: #8A8A8A;
	display: none;
	flex-direction: column;
	height: 100%;
}

.wpgdprc-consent-modal__description--active {
	display: block;
}

.wpgdprc-consent-modal__navigation,
.wpgdprc-consent-modal__information {
    position: relative;
    padding: rem-calc(15);
}

.wpgdprc-consent-modal__information {
    flex-grow: 1;
}

.wpgdprc-consent-modal__navigation {
    flex-shrink: 0;
    border-bottom: 1px solid #EDEDED;
}

.wpgdprc-consent-modal__navigation > a {
    display: block;
    margin-top: 10px;
}

.wpgdprc-consent-modal__navigation > a:first-child {
    margin-top: 0;
}

:root .wpgdprc-consent-modal__navigation-list {
    margin: 0;
    list-style: none;
}

:root .wpgdprc button.wpgdprc-consent-modal__navigation-button {
    border: 0;
    background-color: transparent;
    padding: 0;

    font-size: rem-calc(16);
    line-height: (36 / 16);
    letter-spacing: 0;
    color: #878787;
    text-align: left;
    outline: none;

    @include hocus {
        color: var(--wp-gdpr--button--background-color--darken);;
    }
}

:root .wpgdprc button.wpgdprc-consent-modal__navigation-button--active {
    color: var(--wp-gdpr--button--background-color);
    font-weight: bold;
}

:root .wpgdprc-content-modal__content {
    @include text();
    line-height: (26/16);
    letter-spacing: 0;
    color: $color-gray-dark;

    > * {
        margin-bottom: rem-calc(26);

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.wpgdprc-content-modal__options {
    margin-top: auto;

    > * {
        margin-top: rem-calc(26);
    }
}

.wpgdprc-consent-modal__footer {
    display: flex;
    justify-content: flex-end;
    padding: rem-calc(15);

    :root & .wpgdprc-button:not(.has-background) {
        @include vo-button;

        color: var(--wp-gdpr--button--color);
        background-color: var(--wp-gdpr--button--background-color);

        @include hocus {
            background-color: var(--wp-gdpr--button--background-color--darken);
            border-color: var(--wp-gdpr--button--background-color--darken);
        }
    }
}

.wpgdprc-consent-modal__footer__information {
    flex-grow: 1;
    position: relative;
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 768px) {
    .wpgdprc-consent-modal__header {
        padding: rem-calc(24 25);
    }

    :root .wpgdprc-consent-modal button.wpgdprc-consent-modal__close {
        top: rem-calc(20);
        right: rem-calc(15);
    }

    .wpgdprc-consent-modal__body {
        display: flex;
        flex-direction: row;
    }
    .wpgdprc-consent-modal__navigation,
    .wpgdprc-consent-modal__information {
        padding: rem-calc(25);
    }

    .wpgdprc-consent-modal__navigation {
        width: rem-calc(227);
        border-bottom: none;
    }

    .wpgdprc-consent-modal__footer {
        padding: rem-calc(25);
    }
}

abbr.wpgdprc-required + abbr.required {
    display: none !important;
}

@keyframes wpgdprcFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes wpgdprcFadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
