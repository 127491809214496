/**
 * Consent bar.
 * ----------------------------------------------------------------------------
 */
.wpgdprc-consent-bar {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    margin: rem-calc(15);
    font-family: var(--wp-gdpr--bar--font-family, inherit); // should default to the global font off the site.
    z-index: 99999;
}

.wpgdprc-consent-bar__inner {
    color: var(--wp-gdpr--bar--color);
    background-color: var(--wp-gdpr--bar--background-color);
    padding: rem-calc(20 10);
    box-shadow: $shadow;
    text-align: center;
    z-index: 999;
    animation: wpgdprcFadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
    border-radius: 5px;
    max-width: rem-calc(1200);
    margin: 0 auto;
}

.wpgdprc-consent-bar__container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.wpgdprc-consent-bar__column {
    padding: 0 10px;
}

.wpgdprc-consent-bar__column:first-child {
    flex-grow: 1;
}

.wpgdprc-consent-bar__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    text-align: left;
    flex-wrap: wrap;
}

.wpgdprc-consent-bar__column--notice {
    flex-grow: 1;
    margin-bottom: rem-calc(8);
}

.wpgdprc-consent-bar__column--settings {
    margin-left: auto;
}

.wpgdprc-consent-bar__notice {
    font-size: 1rem;

    p {
        margin-bottom: 0;
    }
}

:root .wpgdprc-consent-bar button.wpgdprc-button--accept {
    color: var(--wp-gdpr--button--color);
    background-color: var(--wp-gdpr--button--background-color);
    border-color: var(--wp-gdpr--button--background-color);
    padding: 5px 10px;
    box-shadow: $shadow;
    font-size: rem-calc(16);
    line-height: (21/18);
    font-weight: 700;
    text-decoration: none;
    padding: rem-calc(13 18);
    border-radius: $radius;
    position: relative;
    display: inline-flex;
    width: auto;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all $transition;

    @include hocus {
        background-color: var(--wp-gdpr--button--background-color--darken);
        border-color: var(--wp-gdpr--button--background-color--darken);
    }
}

:root .wpgdprc-consent-bar button.wpgdprc-button--settings {
    color: var(--wp-gdpr--bar--color);
    background-color: transparent;
    box-shadow: none;
    border-color: transparent;

    @include hocus {
        background-color: transparent;
        box-shadow: none;
        border-color: transparent;
        opacity: .75;
    }
}

@include breakpoint(medium) {
    .wpgdprc-consent-bar__column--notice {
        flex-grow: 0;
        margin-bottom: 0;
    }

    .wpgdprc-consent-bar__column--settings {
        margin-left: 0;
    }

    .wpgdprc-consent-bar__content {
        flex-wrap: nowrap;
    }
}

/**
 * Variation
 */

// Top

.wpgdprc-consent-bar--position-top {
    bottom: auto;
    top: 0;

    .admin-bar & {
        top: $wp-admin-bar-height-mobile;
    }

    @include breakpoint($wp-breakpoint-admin-bar) {
        .admin-bar & {
            top: $wp-admin-bar-height-desktop;
        }
    }
}

// Admin

.wpgdprc-consent-bar--admin {
    margin: 0;
    position: relative;


    .wpgdprc-consent-bar__notice {
        max-width: 100%;
    }
}


